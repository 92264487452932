import * as React from 'react'

const ColumnGridTwo = ({ children }) => {
  return (
    <>
      <div className="flex flex-wrap items-center">{children}</div>
    </>
  )
}

export default ColumnGridTwo
